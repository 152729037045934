import UserAttributes from "./UserAttributes";

const UserName = () => {
  const userAttributes = UserAttributes();
  var userId = "notLoggedInDa";

  if (userAttributes != null) {
    userId = userAttributes["given_name"];
    return userId;
  } else {
    return userId;
  }
};

export default UserName;
