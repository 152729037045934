import React from "react";
import { useLocation } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import UserName from "../../components/Auth/UserName";
import UserAccess from "../../components/Auth/UserAccess";

const HomePage = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  var userName = UserName();
  var userAccess = UserAccess();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  <p className="text-lead text-light">
                    Hi {userName === "notLoggedInDa" ? "" : userName}, you are
                    in {process.env.REACT_APP_TENANT} Notifier!
                    {!userAccess || userAccess === "unknown"
                      ? ""
                      : " You have " + userAccess + " access!"}{" "}
                    <br />
                    {/* <TesterButton>tester</TesterButton> */}
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
