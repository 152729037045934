import UserSession from "./UserSession";

const UserSessionToken = () => {
  const userSession = UserSession();

  if (userSession != null) {
    const jwtToken = userSession.accessToken.jwtToken;
    return jwtToken;
  } else {
    return null;
  }
};

export default UserSessionToken;