import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import axios from "axios";
import "../../assets/plugins/nucleo/css/styleNew.css";
import ReactQuill from "react-quill";
import "../../../node_modules/react-quill/dist/quill.snow.css";

import {
  Button,
  CardBody,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Form,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import UserSessionToken from "../../components/Auth/UserSessionToken";

const CreateNotification = (props) => {
  const [notification, setNotification] = useState("");
  const [apiResponse, setApiResponse] = useState({});
  const [checkBoxHeading, setCheckBoxHeading] = useState([
    "JP",
    "DE",
    "SJO",
    "BLR",
    "SEA",
    "IT",
    "ES",
    "FR",
    "AU",
    "UK",
  ]);
  const [notificationBody, setNotificationBody] = useState("");
  const [listOfTeamNames, setListOfTeamNames] = useState({});
  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState({});
  const [selectedAllCheckBoxes, setSelectedAllCheckBoxes] = useState({});

  const [disableSendButton, setDisableSendButton] = useState(false);

  useEffect(() => {
    getListOfTeamNames();
  }, [UserSessionToken()]);

  const getListOfTeamNames = async () => {
    console.log("");
    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "getDistictRecordsFromUsersEntity",
        {headers:{ Authorization: UserSessionToken()}}
    );
    const modifiedTeamNamesObj = result.data.teamNames.reduce((acc, item) => {
      const prefix = item.split("-")[0];
      if (acc[prefix]) {
        acc[prefix].push(item);
      } else {
        acc[prefix] = [item];
      }
      console.log();
      return acc;
    }, {});

    setListOfTeamNames(modifiedTeamNamesObj);
    console.log("List of teamnames ", modifiedTeamNamesObj);
  };

  const checkIfAllSubItemsAreSelected = (
    parentElement,
    selectedCheckBoxesLocal
  ) => {
    let result = true;
    listOfTeamNames[parentElement].forEach((item) => {
      result = selectedCheckBoxesLocal[item] && result;
    });
    console.log("result is ", result);
    return result;
  };

  const handleAllChange = (event) => {
    setSelectedAllCheckBoxes((state) => {
      state[event.target.id] = event.target.checked;
      return state;
    });

    const childItems = {};
    listOfTeamNames[event.target.id].forEach((item) => {
      childItems[item] = event.target.checked;
    });

    setSelectedCheckBoxes((prevState) => ({ ...prevState, ...childItems }));

    console.log(selectedAllCheckBoxes, selectedCheckBoxes, " -- debug 1");
  };

  const handleChange = (event, parentElement) => {
    console.log(
      "debug 2 ",
      event.target.id,
      " -- ",
      event.target.checked,
      " -- ",
      parentElement
    );
    let areAllSubItemsSelected = "";
    setSelectedCheckBoxes((prevState) => {
      const tempObj = {};
      tempObj[event.target.id] = event.target.checked;
      const newState = { ...prevState, ...tempObj };
      areAllSubItemsSelected = checkIfAllSubItemsAreSelected(
        parentElement,
        newState
      );
      return newState;
    });

    setSelectedAllCheckBoxes((prevState) => {
      const tempObj = {};
      tempObj[parentElement] = areAllSubItemsSelected;
      return { ...prevState, ...tempObj };
    });
  };

  const handleChangeProcessUpdate = (e) => {
    let processUpdateDetails = notification;
    processUpdateDetails = e;
    setNotification(processUpdateDetails, () => {
      console.log("Verify Notification", notification);
    });
  };

  //Counting net character --not in used -- not removed for future use
  function countNetCharecter(notificationEntered) {
    let enteredCharLength = notification.length;
    console.log("Total Character with style : ", enteredCharLength);
    let regex = /(<([^>]+)>)/gi;
    let resultNotification = notification
      .replace(regex, "")
      .replace(/&nbsp;/g, "");

    let filteredCharLength = resultNotification.length;
    let tagsCharLength = enteredCharLength - filteredCharLength;
    console.log("Total tags Character  : ", tagsCharLength);
    console.log("Total Net Character  : ", filteredCharLength);
    return filteredCharLength;
  }

  const onSaveRequest = () => {
    console.log("SelectedCheckBoxes", selectedCheckBoxes);
    setDisableSendButton(true);

    const teamNames = Object.keys(selectedCheckBoxes).filter(
      (item) => selectedCheckBoxes[item]
    );
    console.log({ notification, teamNames });

    let notificationError = "";
    let selectedTeamError = "";
    let maxcharacterExceed = "";

    if (!notification || notification.length <= 0) {
      notificationError = "Error!! Please enter notification";
    } else if (!teamNames || teamNames.length <= 0) {
      selectedTeamError = "Error!! Please teams";
      console.log("Notification", notification);
    } else if (notification.length >= 6500) {
      maxcharacterExceed =
        "The character limit exceeded 6500: The total number of characters in the process update with styling is " +
        notification.length;
    }

    if (notificationError || selectedTeamError || maxcharacterExceed) {
      if (notificationError) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: notificationError,
        });
      } else if (selectedTeamError) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: selectedTeamError,
        });
      } else if (maxcharacterExceed) {
        setDisableSendButton(false);
        swal2.fire({
          icon: "error",
          title: "Oops...",
          text: maxcharacterExceed,
        });
      }
    } else {
      let body = { notification, teamNames };
      console.log("body", body);
      console.log("Body JSON", JSON.stringify(body));
      swal2
        .fire({
          title: "Are you sure?",
          text: "This will send process update to selected teams !!!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Add!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal2.fire({
              title: "Notifying",
              text: "Please wait, sending email notifications to applicable team members !!!",
              icon: "warning",
              didOpen: () => {
                swal2.showLoading();
              },
            });
            fetch(
              process.env.REACT_APP_NOTIFIERV2_BACKEND + `sendNewProcessUpdate`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  Authorization: UserSessionToken(),
                },
                body: JSON.stringify(body),
              }
            )
              .then((response) => response.json())
              .then((result) => {
                swal2.close();
                console.log(result);
                setApiResponse(result);
                if (notification === "") {
                  setDisableSendButton(false);
                  swal("Error!!", "Please enter process update :)", "error");
                } else if (result.message === "SUCCESSFUL") {
                  swal({
                    text: result.details,
                    type: "success",
                  }).then((okay) => {
                    if (okay) {
                      window.location.href =
                        process.env.REACT_APP_REDIRECT_URL +
                        "admin/send-notification";
                    }
                  });
                } else {
                  if (result.details) {
                    setDisableSendButton(false);
                    swal({ text: result.details, icon: "error" });
                  }
                }
              });
          }
        });
    }
  };

  function unCheck() {
    var x = document.getElementsByClassName("checkboxSpacing");
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false;
    }
  }

  const onClearRequest = () => {
    console.log(
      "Clear",
      notification,
      selectedCheckBoxes,
      selectedAllCheckBoxes
    );
    setNotification("");
    let emptyList = [];
    unCheck();
    setSelectedAllCheckBoxes(emptyList);
    setSelectedCheckBoxes(emptyList);
  };

  const handleBody = (e) => {
    console.log("Notification Body", e);
    setNotificationBody(e);
  };

  CreateNotification.modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: [3, 4, 5, 6] },
        { font: [] },
      ],
      //       [{ color: [] }, { background: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],

      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["link"],
      ["clean"],
    ],
  };
  CreateNotification.formats = [
    "header",
    "size",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "indent",
    "clean",
    //     'color',
    //     'background',
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Update</h3>
                  </Col>
                </Row>
                <Row style={{ float: "right" }}>
                  <Button
                    disabled={disableSendButton}
                    size="sm"
                    color="primary"
                    float="left"
                    onClick={onSaveRequest}
                  >
                    Send
                  </Button>
                  <Button size="sm" color="primary" onClick={onClearRequest}>
                    Clear
                  </Button>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6> */}
                  <div className="pl-lg-4">
                    <ReactQuill
                      placeholder="Add Process Update..."
                      modules={CreateNotification.modules}
                      formats={CreateNotification.formats}
                      onChange={handleChangeProcessUpdate}
                      value={notification}
                    />
                  </div>
                  <div className="Add">
                    {Object.keys(listOfTeamNames).length &&
                      checkBoxHeading.map((item, index) => {
                        return (
                          <>
                            <h8 className="addNewStyling textSizeSemiHeader upperCaseFont">
                              {item}
                            </h8>

                            <div
                              className="sections textSize upperCaseFont wrapContent"
                              htmlFor="All"
                            >
                              <input
                                type="checkbox"
                                className="checkboxSpacing"
                                onChange={handleAllChange}
                                // checked={checkedItems[item][childItem]}
                                checked={selectedAllCheckBoxes?.[item]}
                                id={item}
                                name="All"
                                value="All"
                              ></input>
                              <label className="checkboxSpacing" htmlFor="All">
                                {item}-All
                              </label>
                            </div>

                            <div className="main-wrapper" key={index}>
                              {listOfTeamNames[item].map(
                                (childItem, childIndex) => {
                                  return (
                                    <div
                                      className="sections textSize upperCaseFont wrapContent"
                                      key={childIndex}
                                    >
                                      <input
                                        type="checkbox"
                                        className="checkboxSpacing"
                                        onChange={(event) => {
                                          handleChange(event, item);
                                        }}
                                        checked={
                                          selectedCheckBoxes?.[childItem]
                                        }
                                        id={childItem}
                                        name={childItem}
                                        value={childItem}
                                      ></input>
                                      <label
                                        className="checkboxSpacing"
                                        htmlFor={childItem}
                                      >
                                        {childItem}
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateNotification;
