import React, { useState, useEffect } from "react";
import Linkify from "react-linkify";

import { Modal } from "react-bootstrap";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// reactstrap components
import {} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import StickyTable from "react-sticky-table-thead";
import UserId from "../../components/Auth/UserId";
import SearchBar from "material-ui-search-bar";
import "../../assets/plugins/nucleo/css/Search.css";
import { sanitize } from "dompurify";

import {
  Button,
  Table,
  Container,
  Row,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

import axios from "axios";
import Header from "components/Headers/Header.js";
import swal from "sweetalert";
import Tooltip from "@mui/material/Tooltip";
import UserSessionToken from "../../components/Auth/UserSessionToken";

var FileSaver = require("file-saver"); //this is used for report download
const FileDownload = require("js-file-download"); //this is used for report download
const Index = (props) => {
  console.log("PROPS in Index file", props);
  const [activeNav, setActiveNav] = useState(1);
  const [listOfNotification, setListOfNotification] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [modalInfo, setModalInfo] = useState([]);
  const [modalInfoSummary, setModalInfoSummary] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [show, setShow] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [tableData, setTableData] = useState([]);
  const [counts, setCounts] = useState({});
  const [search, setSearch] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [date, setDate] = useState(new Date());
  const [showReportModal, setShowReportModal] = useState(false);
  const handleCloseReportModal = () => setShowReportModal(false);
  const handleShowReportModal = () => setShowReportModal(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [emptyStartDate, setEmptyStartDate] = useState("");
  const [emptyEndDate, setEmptyEndDate] = useState("");

  const [openTime, setOpenTime] = useState();

  useEffect(() => {
    getListOfNotification();
  }, [date]);

  const getListOfNotification = async () => {
    const currentUser = UserId();
    //     const currentUser = 'ajgosavi' //for local testing
    console.log("UserID in manager page", currentUser);
    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "getAllUserNotificationDefaultView/" +
        currentUser +
        "/" +
        formatDate(date), {headers:{ Authorization: UserSessionToken()}}
    );
    let customData = [];

    setListOfNotification(result.data);
    console.log("List of User Notifications");
    console.log(result.data);
  };

  const getSummary = async (e) => {
    let result;
    if (e !== undefined) {
      result = await axios.get(
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "getProcessUpdateSummaryforManager/" +
          e, {headers:{ Authorization: UserSessionToken()}}
      );

      let customData = [];
      setSummary(result.data);
      setCounts(result.data.counts);

      let largest = Math.max(
        result.data.didNotopen.length,
        result.data.acknowledged.length,
        result.data.notApplicable.length,
        result.data.trainingRequired.length
      );
      console.log("Max length of Array--> ", largest);
      let tempTableData = [];
      for (let i = 0; i < largest; i++) {
        tempTableData.push({
          didNotOpen: result.data.didNotopen[i] || "-",
          acknowledged: result.data.acknowledged[i] || "-",
          notApplicable: result.data.notApplicable[i] || "-",
          trainingRequired: result.data.trainingRequired[i] || "-",
        });
      }
      setTableData(tempTableData);
      console.log("Temp Table", tempTableData);
      console.log("Table", tableData);
    }
  };

  function formatDate(date) {
    console.log("Date", date);
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  }

  useEffect(() => {
    console.log("listOfNoti", listOfNotification);
    setFilteredNotifications(
      listOfNotification.filter(
        (item, index) =>
          item.processUpdateStatusId.toString().indexOf(search.toLowerCase()) >
            -1 ||
          item.notification.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.date.toString().indexOf(search) > -1 ||
          (item.acknowledgedDate
            ? item.acknowledgedDate.toString()
            : ""
          ).indexOf(search) > -1 ||
          item.status.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  }, [search, listOfNotification]);

  const handleClose = () => {
    let url =
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
      "updateUserActionAndReadTime/" +
      modalInfo.processUpdateStatusId +
      "/" +
      "readOnly";
    console.log("Associate --Resquest URL", url);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: UserSessionToken(),
      },
      body: JSON.stringify({
        startTimeStamp: openTime,
        endTimeStamp: getFormattedDate(),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Result", result);
        setApiResponse(result);
        setShow(false);
      })
      .catch(() => setShow(false));
  };

  const handleShow = () => setShow(true);

  const getFormattedDate = () => {
    let date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ("0" + date.getHours()).slice(-2);
    var min = ("0" + date.getMinutes()).slice(-2);
    var seg = ("0" + date.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seg;
  };

  const handleSummaryClose = () => setShowSummaryModal(false);
  const handleSummaryShow = () => setShowSummaryModal(true);

  const handleAcknowledge = (e) => {
    console.log(e.target.value);
    console.log(modalInfo.processUpdateStatusId);
    const status = e.target.value;
    const processUpdateStatusId = modalInfo.processUpdateStatusId;
    if (status) {
      let url =
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "updateUserActionAndReadTime/" +
        processUpdateStatusId +
        "/" +
        status;
      console.log("Associate --Resquest URL", url);
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: UserSessionToken()
        },
        body: JSON.stringify({
          startTimeStamp: openTime,
          endTimeStamp: getFormattedDate(),
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Result", result);
          setApiResponse(result);
          if (result.message === "Incorrect Signature") {
            swal("Error!!", "Something Wrong :)", "error");
          } else if (result.message === "SUCCESSFUL") {
            swal({
              text: "You have successfully updated the status",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "manager/mainpage";
              }
            });
          }
        });
    }
  };

  const getPageCount = () => {
    console.log("data", listOfNotification);
    console.log("pagesize", pageSize);
    setPageCount(Math.ceil(listOfNotification.length / pageSize));
    console.log("PageCount", pageCount);
  };

  useEffect(() => {
    getPageCount();
  }, []);

  useEffect(
    (e) => {
      console.log("Print e in manager", e);
      getSummary(e);
    },
    [handleSummaryShow]
  );

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  const rowEvents = {
    onClick: (e) => {
      console.log("event", e);
    },
  };

  const handleClick = (e) => {
    setOpenTime(getFormattedDate);

    console.log("event", e);
    setModalInfo(e);
    var bl = toggleTrueFalse();
    console.log("Set Modal info ", modalInfo);
    console.log("togglevalue", bl);
    console.log("sm", JSON.stringify(summary));
  };

  const toggleTrueFalse = () => {
    setShowModal(handleShow);
  };

  const handleClickSummary = (e) => {
    console.log("event", e);
    setModalInfoSummary(e);
    var bl = toggleTrueFalseSummary();
    console.log("Set Modal info ", modalInfoSummary);
    console.log("togglevalue", bl);
    getSummary(e.processUpdateStatusId);
  };

  const toggleTrueFalseSummary = () => {
    setShowSummaryModal(handleSummaryShow);
  };

  const ModalContent = () => {
    return (
      <Modal
        {...props}
        size="lg"
        className="modal-fluid"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Process Update: {modalInfo.processUpdateId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Linkify properties={{ target: "_blank" }}>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: modalInfo.notification,
              }}
            /> */}
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(modalInfo.notification),
              }}
              className="ql-editor"
            ></div>
          </Linkify>
        </Modal.Body>
        <Modal.Footer>
          <div hidden={modalInfo.status !== "Acknowledge"}>
            <Button
              color="primary"
              id="Acknowledge"
              value="Acknowledge"
              active={false}
              disabled
            >
              Acknowledged
            </Button>
          </div>
          <div hidden={modalInfo.status === "Acknowledge"}>
            <Button
              color="primary"
              id="Acknowledge"
              value="Acknowledge"
              onClick={handleAcknowledge}
            >
              Acknowledge
            </Button>
            <Button
              color="danger"
              id="TrainingRequired"
              value="TrainingRequired"
              onClick={handleAcknowledge}
            >
              Training Required
            </Button>
            <Button
              color="secondary"
              id="NotApplicable"
              value="NotApplicable"
              onClick={handleAcknowledge}
            >
              Not Applicable
            </Button>
          </div>
          <Button
            color="secondary"
            onClick={handleClose}
            hidden={process.env.REACT_APP_TENANT === "ADECT"}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalContentSummary = () => {
    return (
      <Modal
        {...props}
        size="lg"
        className="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        show={showSummaryModal}
        onHide={handleSummaryClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notification Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Row>
            <StickyTable height={200}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Total Notifications</th>
                    <th scope="col">Not Opened</th>
                    <th scope="col">Acknowledged</th>
                    <th scope="col">Training Required</th>
                    <th scope="col">Not Applicable</th>
                  </tr>
                </thead>
                <tbody className="pre-scrollable">
                  <tr>
                    <td>
                      {summary.counts && summary.counts.totalNotifications
                        ? summary.counts.totalNotifications
                        : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.unopened
                        ? summary.counts.unopened
                        : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.acknowledged
                        ? summary.counts.acknowledged
                        : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.trainingRequired
                        ? summary.counts.trainingRequired
                        : "-"}
                    </td>
                    <td>
                      {summary.counts && summary.counts.notApplicable
                        ? summary.counts.notApplicable
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </StickyTable>
          </Row>
          <Row>
            <StickyTable>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Not Opened</th>
                    <th scope="col">Acknowledged</th>
                    <th scope="col">Need Training</th>
                    <th scope="col">Marked Not Applicable</th>
                  </tr>
                </thead>
                <tbody className="pre-scrollable">
                  {tableData.map((row) => (
                    <tr>
                      <td>{row.didNotOpen}</td>
                      <td>{row.acknowledged}</td>
                      <td>{row.trainingRequired}</td>
                      <td>{row.notApplicable}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </StickyTable>
          </Row>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const handleFavorite = (e, item) => {
    console.log("event", e.target.value);
    console.log("ID", e, item.processUpdateStatusId);
    if (e.target.value) {
      fetch(
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "addFilterToProcessUpdate/" +
          item.processUpdateStatusId +
          "/" +
          e.target.value,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: UserSessionToken(),
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result.details);
          setApiResponse(result.details);

          if (result.message === "SUCCESSFUL") {
            swal({
              text: "Notification is successfully added to your favourites.",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "manager/mainpage";
              }
            });
          } else {
            if (result.details) {
              swal({ text: result.details, icon: "error" });
            }
          }
        });
    }
  };

  const handleArchive = (e, item) => {
    console.log("event", e.target.value);
    console.log("ID", e, item.processUpdateStatusId);
    if (e.target.value) {
      fetch(
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
          "addFilterToProcessUpdate/" +
          item.processUpdateStatusId +
          "/" +
          e.target.value,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: UserSessionToken(),
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result.details);
          setApiResponse(result.details);

          if (result.message === "SUCCESSFUL") {
            swal({
              text: "Notification is archived successfully",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL + "manager/mainpage";
              }
            });
          } else {
            if (result.details) {
              swal({ text: result.details, icon: "error" });
            }
          }
        });
    }
  };

  function extractPartialNotification(para) {
    let listOfLineBreak = para.split("\n");
    let partialNotification =
      listOfLineBreak[0] +
      "\n" +
      (listOfLineBreak[2] ? listOfLineBreak[2] : "");
    console.log("Extraction", partialNotification);
    return partialNotification;
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="button-styling  btn" onClick={onClick}>
      {value}
    </button>
  );

  const handleDateChange = (e) => {
    console.log("Date in HandleChange", e);
    const date = formatDate(e);
    console.log(" DF in fav: handleDate change", e, date, formatDate(e));
    console.log(" DF in fav: handleDate change Date", date);
    setDate(e);
  };

  const cancelSearch = () => {
    setSearch("");
  };

  const styleObj = {
    //     'margin-left': '-100px',
    "vertical-align": "top",
    "table-layout": "fixed",

    //     display: 'block',
  };

  const handleReportDownload = (e, item) => {
    console.log("event", e.target.value);
    console.log("ID", e, item.processUpdateStatusId);
    const notificationId = item.processUpdateStatusId;
    let fileName = notificationId + "_NotifierReport.xlsx";

    let resource =
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
      "reportsByNotificationforManager/" +
      notificationId;

    //API Call
    axios
      .get(resource,{ headers: {"Authorization" : UserSessionToken()},responseType: 'arraybuffer'  })
      .then((response) => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
            suggestedFileName === undefined ? fileName : suggestedFileName;
        console.log(
            `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
        );
        // Let the user save the file.
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, effectiveFileName);
      })
      .catch((response) => {
        console.error(
          "Could not Download the Excel report from the backend.",
          response
        );
      });
    // }
  };

  const ModalContentReport = () => {
    return (
      <Modal size="sm" show={showReportModal} onHide={handleCloseReportModal}>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            class="textSizeHeader addNewStyling"
          >
            {" "}
            Download Your Report Here
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              isClearable
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              minDate={new Date(2020, 0, 1)}
              placeholderText=" Start Date.."
            />
          </Row>
          <div style={{ color: "red" }}>{emptyStartDate}</div>
          <br></br>
          <Row>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              isClearable
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              minDate={new Date(2020, 0, 1)}
              placeholderText=" End Date.."
            />
          </Row>
          <div style={{ color: "red" }}>{emptyEndDate}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            value="Download"
            onClick={handleDownloadReport}
          >
            Download
          </Button>
          <Button
            color="danger"
            value="Delete Update"
            onClick={handleCloseReport}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const validate = () => {
    let error1, error2, error3;
    if (startDate === null) {
      error1 = "Please enter start date!!";
      setEmptyStartDate(error1);
      setEmptyEndDate("");
    } else if (endDate === null) {
      error2 = "Please enter end date!!";
      setEmptyEndDate(error2);
      setEmptyStartDate("");
    } else {
      if (startDate < endDate) error3 = "Start Date is ";
      setEmptyStartDate("");
      setEmptyStartDate("");
    }

    if (error1 || error2) {
      return false;
    } else {
      return true;
    }
  };

  const handleDownloadReport = (e) => {
    let currentUser = UserId();
    console.log("StartDate", startDate);
    console.log("EndDate", endDate);
    console.log(e.target.value);
    let val = validate();
    console.log("Validate", val);

    if (validate()) {
      let formattedStartDate = formatDate(startDate);
      let formattedEndDate = formatDate(endDate);

      let fileName =
        "NotifierReport_From_" +
        formattedStartDate +
        "_to_" +
        formattedEndDate +
        ".xlsx";
      console.log(
        "StartDate and EndDate: ",
        formattedStartDate,
        formattedEndDate
      );

      let resource =
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "reportsBydatesforManager/" +
        currentUser +
        "/" +
        formattedStartDate +
        "/" +
        formattedEndDate;

      //API Call
      axios
        .get(resource,{ headers: {"Authorization" : UserSessionToken()},responseType: 'arraybuffer' })
        .then((response) => {
          // Log somewhat to show that the browser actually exposes the custom HTTP header
          const fileNameHeader = "x-suggested-filename";
          const suggestedFileName = response.headers[fileNameHeader];
          const effectiveFileName =
            suggestedFileName === undefined ? fileName : suggestedFileName;
          console.log(
            `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
          );

          // Let the user save the file.
          var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          FileDownload(blob,effectiveFileName)
        })
        .catch((response) => {
          console.error(
            "Could not Download the Excel report from the backend.",
            response
          );
        });
    }
    setShow(false);
  };

  const handleCloseReport = () => {
    setEmptyEndDate("");
    setEmptyStartDate("");
    setShowReportModal(false);
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Process Updates</h3>
                    {showReportModal ? <ModalContentReport /> : null}
                  </div>
                  <div className="button headerInline headerMargin textSizeHeader">
                    <button className="link-align disable-styling button-styling">
                      Month
                      <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        onChange={handleDateChange}
                        selected={date ? date : new Date()}
                        maxDate={new Date()}
                        minDate={new Date(2020, 0, 1)}
                        customInput={<ExampleCustomInput />}
                      ></DatePicker>
                    </button>
                  </div>
                  <div className="searchArea">
                    <SearchBar
                      className="search"
                      value={search}
                      placeholder="Search here"
                      onChange={(searchVal) => setSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                  </div>
                </Row>
              </CardHeader>

              <StickyTable height={600}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope="col">ID</th> */}
                      <th scope="col">Notification</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Date Of Acknowledgement</th>
                      <th scope="col">Status</th>
                      <th scope="col">
                        <Tooltip title="Report">
                          <button
                            style={{
                              color: "white",
                              "background-color": "Transparent",
                              "background-repeat": "no-repeat",
                              border: "none",
                              cursor: "pointer",
                              overflow: "hidden",
                              outline: "none",
                              padding: "0.3rem 0.3rem",
                              "overflow-x": "hidden",
                            }}
                            onClick={handleShowReportModal}
                          >
                            <i class="fa fa-download"></i>
                          </button>
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredNotifications.map((item) => (
                      <tr>
                        {/* <td>{item.processUpdateStatusId}</td> */}
                        <td style={styleObj} onClick={(e) => handleClick(item)}>
                          {Number(item.date.split("-")[0]) < 2022 ||
                          item.notification.includes("\r\n") ? (
                            extractPartialNotification(item.notification)
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitize(
                                  item.notification.split("<br>")[0]
                                ),
                              }}
                              className="ql-editor"
                            ></div>
                          )}
                        </td>
                        <td>{item.date ? item.date.split(" ")[0] : null}</td>
                        <td>
                          {item.acknowledgedDate
                            ? item.acknowledgedDate.split(" ")[0]
                            : null}
                        </td>
                        <td>
                          {item.status === "Acknowledge"
                            ? "Acknowledged"
                            : item.status}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i
                                className="fas fa-ellipsis-v"
                                style={{ color: "#1da1f2a8" }}
                              />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                value="favorite"
                                onClick={(e) => handleFavorite(e, item)}
                              >
                                Mark As Favorite
                              </DropdownItem>
                              <DropdownItem
                                value="Archive"
                                onClick={(e) => handleArchive(e, item)}
                              >
                                Mark As Archived
                              </DropdownItem>
                              <DropdownItem
                                value="Summary"
                                onClick={(e) => handleClickSummary(item)}
                              >
                                Summary
                              </DropdownItem>
                              <DropdownItem
                                value="Summary"
                                onClick={(e) => handleReportDownload(e, item)}
                              >
                                Download Report
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </StickyTable>
              {show ? <ModalContent /> : null}
              {showSummaryModal ? <ModalContentSummary /> : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Index;
