import Index from "views/Index.js";

import Favourite from "views/examples/Favourite";
import Archived from "views/examples/Archived";
import HomePage from "views/examples/HomePage";

var associateRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-archive-2 text-primary",
    component: HomePage,
    layout: "/associates",
  },
  {
    path: "/mainpage",
    name: "Associate Notifications",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/associates",
  },
  {
    path: "/notifications/favourite",
    name: "Favourite Notifications",
    icon: "ni ni-favourite-28 text-primary",
    component: Favourite,
    layout: "/associates",
  },
  {
    path: "/notifications/archived",
    name: "Archived Notifications",
    icon: "ni ni-archive-2 text-primary",
    component: Archived,
    layout: "/associates",
  },
];

export default associateRoutes;
