import React, { useState, useEffect } from "react";

const CombinationsTable = (props) => {
  console.log("CT", props);

  const [teamCombinations, setTeamCombinations] = useState([]);

  const [user, setUser] = useState(null);
  useEffect(() => {
    const formatTeamCombinations = async () => {
      if (props.teams) {
        let combinationArray = props.teams.split(",");

        let teamCombinations = [];

        combinationArray.forEach((combination) => {
          console.log("combi", combination);
          let program = combination.substring(0, combination.indexOf("+"));
          combination = combination.substring(combination.indexOf("+") + 1);
          let task = combination.substring(0, combination.indexOf("+"));
          combination = combination.substring(combination.indexOf("+") + 1);
          let marketplace = combination.substring(0, combination.indexOf("+"));
          let site = combination.substring(combination.indexOf("+") + 1);

          teamCombinations.push({
            program: program,
            task: task,
            marketplace: marketplace,
            site: site,
          });
        });

        setTeamCombinations(teamCombinations);
      }
    };
    formatTeamCombinations();
  }, [props]);

  return (
    <div>
      <div className="container">
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table className="table table-bordered table-hover" id="tab_logic">
              <thead>
                <tr>
                  <th className="text-center"> Program </th>
                  <th className="text-center"> Task </th>
                  <th className="text-center"> Marketplace </th>
                  <th className="text-center"> Site </th>
                </tr>
              </thead>
              <tbody>
                {teamCombinations.map((combination, idx) => (
                  <tr id="addr0" key={idx}>
                    <td>{combination.program}</td>
                    <td>{combination.task}</td>
                    <td>{combination.marketplace}</td>
                    <td>{combination.site}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinationsTable;
