import User from "./User";

const UserSession = () => {
  const user = User();

  if (user != null) {
    const token = user.signInUserSession;
    console.log("TOKEN",token)
    return token;
  } else {
    return null;
  }
};

export default UserSession;