import UserAttributes from "./UserAttributes";
import User from "./User";

const UserAccess = () => {
  let userId, uID;
  const userAttributes = UserAttributes();
  const user = User();
  if (user != null) {
    userId = user.username;
    userId = userId.replace("@amazon.com", "");
    userId = userId.replace("copshomeusers_", "");
    userId = userId.replace("copshomeusers_", "");
    userId = userId.replace("asqs-notifier-users_", "");

    uID = userId;
  }
  var access = "unknown";

  if (userAttributes != null) {
    const ldapGroups = userAttributes["custom:groups"];
    const isManager =
      userAttributes["custom:isManager"] &&
      userAttributes["custom:isManager"] === "0";
    console.log("rawuserAttributess", userAttributes);
    console.log("isManager? : ", userAttributes["custom:isManager"], isManager);

    if (ldapGroups) {
      if (
        ldapGroups.includes("adect-notifier-admin") ||
        ldapGroups.includes("Aquiline-Admin") ||
        ldapGroups.includes("gco-notifier-admin") ||
        ldapGroups.includes("asqs-notifier-admin")
      ) {
        access = "Admin";
      } else if (isManager || ldapGroups.includes("adect-notifier-wfm")) {
        access = "WFM";
      } else {
        access = "Tester";
      }
    } else if (isManager) {
      access = "WFM";
    } else {
      access = "Tester";
    }
  }
  return access;
};

export default UserAccess;
