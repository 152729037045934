import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
// reactstrap components
import {} from "reactstrap";
import Linkify from "react-linkify";

import {
  Button,
  Table,
  Container,
  Row,
  Card,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import axios from "axios";
import Header from "components/Headers/Header.js";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sanitize } from "dompurify";
import SearchBar from "material-ui-search-bar";
import "../../assets/plugins/nucleo/css/Search.css";
import UserId from "../../components/Auth/UserId";
import UserSessionToken from "../../components/Auth/UserSessionToken";

const Archived = (props) => {
  console.log("PROPS in Index file", props);
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState();
  const [listOfNotification, setListOfNotification] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState();
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [apiResponse, setApiResponse] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [date, setDate] = useState(new Date());
  const [formatedDate, setFormatedDate] = useState("");

  const [enableThumbs, setEnableThumbs] = useState(true);

  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();

  useEffect(() => {
    getListOfNotification();
  }, [date]);

  const getListOfNotification = async () => {
    const currentUser = UserId();
    console.log();
    const result = await axios.get(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "getUserNotificationDetailsByFilters/" +
        currentUser +
        "/archive" +
        "/" +
        formatDate(date),
        {headers:{ Authorization: UserSessionToken()}}
    );

    setListOfNotification(result.data);
    console.log("List of User Notifications");
    console.log(result.data);
  };
  function formatDate(date) {
    console.log("Date", date);
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  }

  useEffect(() => {
    setFilteredNotifications(
      listOfNotification.filter(
        (item) =>
          item.processUpdateStatusId.toString().indexOf(search) > -1 ||
          item.notification.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.date.toString().indexOf(search) > -1 ||
          (item.acknowledgedDate
            ? item.acknowledgedDate.toString()
            : ""
          ).indexOf(search) > -1 ||
          item.status.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  }, [search, listOfNotification]);

  const handleClose = () => {
    let url =
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
      "updateUserActionAndReadTime/" +
      modalInfo.processUpdateStatusId +
      "/" +
      "readOnly";
    console.log("Associate --Resquest URL", url);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: UserSessionToken(),
      },
      body: JSON.stringify({
        startTimeStamp: openTime,
        endTimeStamp: getFormattedDate(),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Result", result);
        setApiResponse(result);
        setShow(false);
      })
      .catch(() => setShow(false));
  };

  const handleShow = () => setShow(true);

  const getFormattedDate = () => {
    let date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ("0" + date.getHours()).slice(-2);
    var min = ("0" + date.getMinutes()).slice(-2);
    var seg = ("0" + date.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seg;
  };

  const getPageCount = () => {
    console.log("data", listOfNotification);
    console.log("pagesize", pageSize);
    setPageCount(Math.ceil(listOfNotification.length / pageSize));
    console.log("PageCount", pageCount);
  };

  useEffect(() => {
    getPageCount();
  }, []);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const rowEvents = {
    onClick: (e) => {
      console.log("event", e);
    },
  };

  const handleUnArchive = (item) => {
    console.log("unfavHandle", item);
    console.log("ID-event", item);
    fetch(
      process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "addFilterToProcessUpdate/" +
        item.processUpdateStatusId +
        "/" +
        "default",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: UserSessionToken()
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.details);
        setApiResponse(result.details);

        if (result.message === "SUCCESSFUL") {
          swal({
            text: "Notification is successfully Unarchived.",
            type: "success",
          }).then((okay) => {
            if (okay) {
              window.location.href =
                process.env.REACT_APP_REDIRECT_URL + "associates/mainpage";
            }
          });
        } else {
          if (result.details) {
            swal({ text: result.details, icon: "error" });
          }
        }
      });
  };

  const handleClick = (e) => {
    setOpenTime(getFormattedDate);
    console.log("event", e);
    setModalInfo(e);
    var bl = toggleTrueFalse();
    console.log("Set Modal info ", modalInfo);
    console.log("togglevalue", bl);
  };

  const toggleTrueFalse = () => {
    setShowModal(handleShow);
  };

  const handleAcknowledge = (e) => {
    console.log(e.target.value);
    console.log(modalInfo.processUpdateStatusId);
    const status = e.target.value;
    const processUpdateStatusId = modalInfo.processUpdateStatusId;

    console.log("times : ", openTime, closeTime);

    if (status) {
      let url =
        process.env.REACT_APP_NOTIFIERV2_BACKEND +
        "updateUserActionAndReadTime/" +
        processUpdateStatusId +
        "/" +
        status;
      console.log("Associate --Resquest URL", url);
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: UserSessionToken(),
        },
        body: JSON.stringify({
          startTimeStamp: openTime,
          endTimeStamp: getFormattedDate(),
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Result", result);
          setApiResponse(result);
          if (result.message === "Incorrect Signature") {
            swal("Error!!", "Something Wrong :)", "error");
          } else if (result.message === "SUCCESSFUL") {
            swal({
              // text: 'You have successfully selected : ' + status,
              text: "You have successfully updated the status",
              type: "success",
            }).then((okay) => {
              if (okay) {
                window.location.href =
                  process.env.REACT_APP_REDIRECT_URL +
                  "associates/notifications/archived";
              }
            });
          }
        });
    }
  };

  const styleObj = {
    //     'margin-left': '-100px',
    "vertical-align": "top",
    "table-layout": "fixed",

    //     display: 'block',
  };

  const ModalContent = () => {
    return (
      <Modal
        {...props}
        size="lg"
        className="modal-fluid"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Process Update: {modalInfo.processUpdateId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Linkify properties={{ target: "_blank" }}>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: modalInfo.notification,
              }}
            /> */}
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(modalInfo.notification),
              }}
              className="ql-editor"
            ></div>
          </Linkify>
        </Modal.Body>
        <Modal.Footer>
          <div hidden={modalInfo.status !== "Acknowledge"}>
            <Button
              color="primary"
              id="Acknowledge"
              value="Acknowledge"
              active={false}
              disabled
            >
              Acknowledged
            </Button>
          </div>
          <div hidden={modalInfo.status === "Acknowledge"}>
            <Button
              color="primary"
              id="Acknowledge"
              value="Acknowledge"
              onClick={handleAcknowledge}
            >
              Acknowledge
            </Button>
            <Button
              color="danger"
              id="Training Required"
              value="Training Required"
              onClick={handleAcknowledge}
            >
              Training Required
            </Button>
            <Button
              color="secondary"
              id="Not Applicable"
              value="Not Applicable"
              onClick={handleAcknowledge}
            >
              Not Applicable
            </Button>
          </div>
          <Button
            color="secondary"
            onClick={handleClose}
            hidden={process.env.REACT_APP_TENANT === "ADECT"}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function extractPartialNotification(para) {
    let listOfLineBreak = para.split("\n");
    let partialNotification =
      listOfLineBreak[0] +
      "\n" +
      "\n" +
      (listOfLineBreak[2] ? listOfLineBreak[2] : "");
    console.log("Extraction", partialNotification);
    return partialNotification;
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="button-styling  btn" onClick={onClick}>
      {value}
    </button>
  );

  const handleDateChange = (e) => {
    console.log("Date in HandleChange", e);
    const date = formatDate(e);
    console.log(" DF: handleDate change", e, date, formatDate(e));
    console.log(" DF: handleDate change Date", date);
    setDate(e);
  };

  const cancelSearch = () => {
    setSearch("");
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Process Updates</h3>
                  </div>
                  <div className="button headerInline headerMargin textSizeHeader">
                    <button className="link-align disable-styling button-styling">
                      Month
                      <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        onChange={handleDateChange}
                        selected={date ? date : new Date()}
                        maxDate={new Date()}
                        minDate={new Date(2020, 0, 1)}
                        customInput={<ExampleCustomInput />}
                      ></DatePicker>
                    </button>
                  </div>
                  {/* </div> */}
                  <div className="searchArea">
                    <SearchBar
                      className="search"
                      value={search}
                      placeholder="Search here"
                      onChange={(searchVal) => setSearch(searchVal)}
                      onCancelSearch={() => cancelSearch()}
                    />
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col">ID</th> */}
                    <th scope="col">Notification</th>
                    <th scope="col">Date Received</th>
                    <th scope="col">Date Of Acknowledgement</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {filteredNotifications.map((item) => (
                    <tr>
                      {/* <td>{item.processUpdateStatusId}</td> */}
                      <td style={styleObj} onClick={(e) => handleClick(item)}>
                        {Number(item.date.split("-")[0]) < 2022 ||
                        item.notification.includes("\r\n") ? (
                          extractPartialNotification(item.notification)
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitize(
                                item.notification.split("<br>")[0]
                              ),
                            }}
                            className="ql-editor"
                          ></div>
                        )}
                        {/* {item.notification} */}
                      </td>
                      <td>{item.date ? item.date.split(" ")[0] : null}</td>
                      <td>
                        {item.acknowledgedDate
                          ? item.acknowledgedDate.split(" ")[0]
                          : null}
                      </td>
                      <td>
                        {item.status === "Acknowledge"
                          ? "Acknowledged"
                          : item.status}
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i
                              className="fas fa-ellipsis-v"
                              style={{ color: "#1da1f2a8" }}
                            />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              value="favorite"
                              onClick={() => handleUnArchive(item)}
                            >
                              Remove from archives
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>{" "}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {show ? <ModalContent /> : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Archived;
