import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6"></Col>

        <Col xl="6"></Col>
      </Row>
    </footer>
  );
};

export default Footer;
