import { Auth } from "aws-amplify";

var dummyUser = null;

const User = () => {
  if (dummyUser != null) {
    return dummyUser;
  }

  const user = Auth.user;

  if (user != null) {
    dummyUser = user;
    return user;
  } else {
    return null;
  }
};

export default User;
