import User from "./User";

const UserAttributes = () => {
  const user = User();

  if (user != null) {
    const attributes = user.attributes;
    return attributes;
  } else {
    return null;
  }
};

export default UserAttributes;
