import Favourite from "views/examples/Favourite";
import Archived from "views/examples/Archived";
import ManagerPage from "views/examples/ManagerPage";
import HomePage from "views/examples/HomePage";

var managerRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "ni ni-archive-2 text-primary",
    component: HomePage,
    layout: "/manager",
  },
  {
    path: "/mainpage",
    name: "Manager Notifications",
    icon: "ni ni-archive-2 text-primary",
    component: ManagerPage,
    layout: "/manager",
  },
  {
    path: "/notifications/favourite",
    name: "Favourite Notifications",
    icon: "ni ni-favourite-28 text-primary",
    component: Favourite,
    layout: "/manager",
  },
  {
    path: "/notifications/archived",
    name: "Archived Notifications",
    icon: "ni ni-archive-2 text-primary",
    component: Archived,
    layout: "/manager",
  },
];

export default managerRoutes;
