import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Nav,
  Container,
  Media,
  Row,
  Col,
  NavItem,
  NavLink,
  UncontrolledCollapse,
} from "reactstrap";
import UserFullName from "../Auth/UserFullName";
import UserId from "../Auth/UserId";

const AssociateNavBar = (props) => {
  const currentUser = UserId();

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container fluid>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/test"></Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/associates"
            >
              {props.brandText}
            </Link>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink className="nav-link-icon" to="/" tag={Link}>
                  <i className="ni ni-planet" />
                  <span className="nav-link-inner--text">menu</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
                          currentUser
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        <UserFullName />
                      </span>
                    </Media>
                  </Media>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AssociateNavBar;
