import User from "./User";

var uID = null;
var userId = "notLoggedInDa";

const UserId = () => {
  if (uID !== "notLoggedInDa" && uID != null) {
    return uID;
  }

  const user = User();
  console.log("rawuser", user);

  if (user != null) {
    userId = user.username;
    if (userId.includes("@")) {
      userId = userId.substring(0, userId.lastIndexOf("@"));
    }
    userId = userId.replace("copshomeusers_", "");
    userId = userId.replace("gco-notifier-users_", "");
    userId = userId.replace("asqs-notifier-users_", "");
    uID = userId;
  }
  console.log("rawuserId", userId);
  return userId;
};

export default UserId;
